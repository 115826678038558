<script setup lang="ts">
import { onMounted, ref } from '#imports'
import type { Placement } from '@floating-ui/dom'
import { flip, shift, size } from '@floating-ui/dom'
import { onClickOutside } from '@vueuse/core'
import type { Ref } from 'vue'
import useFloatingUi from '../composables/useFloatingUi'

const props = defineProps<{
  anchor: Ref<Element> | Element
  placement: Placement
}>()

const emit = defineEmits(['click-outside'])

const root = ref()

const maxDimensions = ref({
  maxHeight: '100%',
  maxWidth: '100%',
})

const middleware = [
  flip({ padding: 8 }),
  size({
    apply({ availableHeight, availableWidth }) {
      maxDimensions.value = {
        maxHeight: `${availableHeight}px`,
        maxWidth: `${availableWidth}px`,
      }
    },
    padding: 8,
  }),
  shift({ padding: 8 }),
]

const { floatingStyles, updateFloating } = useFloatingUi(
  props.anchor,
  root as Ref<HTMLElement>,
  props.placement,
  middleware,
)

onMounted(() => {
  updateFloating()
})

onClickOutside(root, (e) => {
  emit('click-outside', e)
})
</script>

<template>
  <div
    ref="root"
    class="card z-1000 absolute max-w-prose"
    :style="{ ...floatingStyles, ...maxDimensions }"
  >
    <slot v-bind="{ maxDimensions }" />
  </div>
</template>
